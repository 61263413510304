:root {
  --green1: rgb(18, 63, 28);
  --green2: rgb(28, 111, 41);
  --green3: rgb(117, 178, 29);

  --blue1: rgb(21, 31, 59);
  --blue2: rgb(8, 70, 124);
  --blue3: rgb(17, 139, 215);
  --black: rgb(70, 70, 70);

  --grayBackground: rgb(238, 237, 237);
  --whiteBackground: rgb(255, 255, 255);

  --menuTopLeft: rgba(61, 152, 114, 1);
  --menuTopRight: rgb(35, 153, 152);
  --menuBottomLeft: rgba(60, 152, 113, 1);
  --menuBottomRight: rgb(34, 152, 152);
  
  font-family: Arial, 'Arial Narrow Bold';
  background-color: var(--whiteBackground);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

#root {
  height: 100vh;
}

/* Border componente input HTML */
#biografia {
  border-radius: 20px;
}

.e-toolbar-items, #biografia_toolbar, #biografia_toolbar_wrapper {
  border-radius: 20px 20px 0 0 !important;
}

#biografiarte-view {
  border-radius: 0 0 20px 20px !important;
}

/* .css-1nblt34-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 15px !important;
}

.css-119t6fi-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 15px !important;
} */

.MuiInputBase-root{
  border-radius: 15px !important;
}

.css-1xhypcz-MuiStack-root>.MuiTextField-root{
  margin-top: -3px;
}

.css-1xhypcz-MuiStack-root>.MuiTextField-root {
  width: 90vh;
}

.MuiStack-root{
  width: 100% !important;
  margin-top: -9px;
}

.css-10e3ze8-MuiStack-root>.MuiTextField-root{
  min-width: 100px !important;
}
